import { Injectable } from '@angular/core';
import { ColorsService } from './colors.service';

@Injectable({
  providedIn: 'root'
})

export class FamiliesService {

	public colors;

	data: Array<{id: string, name: string, label: string, title: string, images: Array<string>, colors: Array<{}>}>;

  	constructor( private colorsService: ColorsService ) { 
  		
  		this.data= [
  			{
  				id : '0001',
  				name : 'Black & White',
  				label : 'black-and-white',
  				title: 'Black & White',
  				images: ['/assets/images/families/black-and-white/black-and-white.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'black-and-white' )
  			},
  			{
  				id : '0002',
  				name : 'Marbre',
  				label : 'marbre',
  				title: 'Marbre',
  				images: ['/assets/images/families/marbre/marbre.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'marbre' )
  			},
  			{
  				id : '0003',
  				name : 'Loft',
  				label : 'loft',
  				title: 'Loft',
  				images: ['/assets/images/families/loft/loft.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'loft' )
  			},
  			{
  				id : '0004',
  				name : 'Madera',
  				label : 'madera',
  				title: 'Madera',
  				images: ['/assets/images/families/madera/madera.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'madera' )
  			},
  			{
  				id : '0005',
  				name : 'Unis',
  				label : 'unis',
  				title: 'Unis',
  				images: ['/assets/images/families/unis/unis.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'unis' )
  			},
  			{
  				id : '0006',
  				name : 'Mineralis',
  				label : 'mineralis',
  				title: 'Mineralis',
  				images: ['/assets/images/families/mineralis/mineralis.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'mineralis' )
  			},
  			{
  				id : '0007',
  				name : 'Artisanal',
  				label : 'artisanal',
  				title: 'Artisanal',
  				images: ['/assets/images/families/artisanal/artisanal.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'artisanal' )
  			},
  			{
  				id : '0008',
  				name : 'Vegetalis',
  				label : 'vegetalis',
  				title: 'Vegetalis',
  				images: ['/assets/images/families/vegetalis/vegetalis.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'vegetalis' )
  			},
  			{
  				id : '0009',
  				name : 'Art Déco',
  				label : 'artdec',
  				title: 'Art Déco',
  				images: ['/assets/images/families/artdec/artdec.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'artdec' )
  			},
  			{
  				id : '0010',
  				name : 'Panoramique',
  				label : 'panoramique',
  				title: 'Panoramique',
  				images: ['/assets/images/families/panoramique/panoramique.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'panoramique' )
  			},
  			{
  				id : '0011',
  				name : 'À la Bastide',
  				label : 'a-la-bastide',
  				title: 'MK À la Bastide',
  				images: ['/assets/images/families/a-la-bastide/a-la-bastide.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'a-la-bastide' )
  			},
  			{
  				id : '0012',
  				name : 'Vallon des Auffes',
  				label : 'vallon-des-auffes',
  				title: 'MK Vallon des Auffes',
  				images: ['/assets/images/families/vallon-des-auffes/vallon-des-auffes.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'vallon-des-auffes' )
  			},
  			{
  				id : '0013',
  				name : 'Collector',
  				label : 'collector',
  				title: 'Collector',
  				images: ['/assets/images/families/collector/collector.jpg'],
  				colors: this.colorsService.getColorsByFamily( 'collector' )
  			}
  			// {
  			// 	id : '0001',
  			// 	name : 'Nouveautés',
  			// 	label : 'nouveautes',
  			// 	title: 'Nouveautés',
  			// 	images: ['/assets/images/families/nouveautes/nouveautes.png'],
  			// 	colors: this.colorsService.getColorsByFamily( 'nouveautes' )
  			// },
  			// {
				// 	id : '0002',
				// 	name : 'À la Bastide',
				// 	label : 'a-la-bastide',
				// 	title : 'À la Bastide',
				// 	images : ['/assets/images/families/a-la-bastide/a-la-bastide.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'a-la-bastide' )
				// },
				// {
				// 	id : '0003',
				// 	name : 'Vallon des Auffes',
				// 	label : 'vallon-des-auffes',
				// 	title : 'Vallon des Auffes',
				// 	images : ['/assets/images/families/vallon-des-auffes/vallon-des-auffes.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'vallon-des-auffes' )
				// },
	  		// {
	  		// 	id : '0004',
	  		// 	name : 'Harmonie',
	  		// 	label : 'harmonie',
	  		// 	title : 'Harmonie',
	  		// 	images : ['/assets/images/families/harmonie/harmonie.png'],
	  		// 	colors : this.colorsService.getColorsByFamily( 'harmonie' )
	  		// },
	  		// {
	  		// 	id : '0005',
	  		// 	name : 'Art déco',
	  		// 	label : 'artdec',
	  		// 	title : 'Art déco',
	  		// 	images : ['/assets/images/families/artdec/artdec.png'],
	  		// 	colors : this.colorsService.getColorsByFamily( 'artdec' )
	  		// },
	  		// {
	  		// 	id : '0006',
	  		// 	name : 'Elegance',
	  		// 	label : 'elegance',
	  		// 	title : 'Elegance',
	  		// 	images : ['/assets/images/families/elegance/elegance.png'],
	  		// 	colors : this.colorsService.getColorsByFamily( 'elegance' )
	  		// },
				// {
				// 	id : '0007',
				// 	name : 'Classique Chic',
				// 	label : 'classique-chic',
				// 	title : 'Classique Chic',
				// 	images : ['/assets/images/families/classique-chic/classique-chic.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'classique-chic' )
				// },
				// {
				// 	id : '0008',
				// 	name : 'Cosy',
				// 	label : 'cosy',
				// 	title : 'Cosy',
				// 	images : ['/assets/images/families/cosy/cosy.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'cosy' )
				// },
				// {
				// 	id : '0009',
				// 	name : 'Moderne Design',
				// 	label : 'moderne-design',
				// 	title : 'Moderne Design',
				// 	images : ['/assets/images/families/moderne-design/moderne-design.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'moderne-design' )
				// },
				// {
				// 	id : '0010',
				// 	name : 'Artiste',
				// 	label : 'artiste',
				// 	title : 'Artiste',
				// 	images : ['/assets/images/families/artiste/artiste.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'artiste' )
				// },
				// {
				// 	id : '0011',
				// 	name : 'Autour du monde',
				// 	label : 'autour-du-monde',
				// 	title : 'Autour du monde',
				// 	images : ['/assets/images/families/autour-du-monde/autour-du-monde.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'autour-du-monde' )
				// },
				// {
				// 	id : '0012',
				// 	name : 'Structure',
				// 	label : 'structure',
				// 	title : 'Structure',
				// 	images : ['/assets/images/families/structure/structure.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'structure' )
				// },
				// {
				// 	id : '0013',
				// 	name : 'Vintage',
				// 	label : 'vintage',
				// 	title : 'Vintage',
				// 	images : ['/assets/images/families/vintage/vintage.png'],
				// 	colors : this.colorsService.getColorsByFamily( 'vintage' )
				// }
			] 	
  	}

  	getFamilyByLabel(label)
  	{
		for ( let family of this.data ) {
  			if ( family.label == label ) {
  				return family;
  			}
  		}
  	}

  	getNextFamily(id) 
  	{
  		const families = this.data;

  		for (let i in families ) {
  			if (families[i].id == id ) {
  				let next = parseInt(i)+1;
				return next < families.length 
					? families[next] 
					: families[0];
  			}
  		}
	}

  	getPreviousFamily(id)
  	{
  		const families = this.data;

  		for (let i in families ) {
  			if (families[i].id == id ) {
  				let prev = parseInt(i)-1;
  				return prev == -1
  					? families[families.length-1]
  					: families[prev];
  			}
  		}
  	}
}
