import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { VideoPlayer } from './components/video-player/video-player.component';
//import {registerWebPlugin} from "@capacitor/core";
import {FileSharer} from '@byteowls/capacitor-filesharer';
import { Plugins, registerWebPlugin, StatusBarBackgroundColorOptions } from '@capacitor/core';
const { SplashScreen, StatusBar } = Plugins;
//import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // public appPages = [
  //   {
  //     title: 'Nuancier',
  //     url: '/colors'
  //   },
  //   {
  //     title: 'Catalogue',
  //     url: '/catalog'
  //   },
  //   {
  //     title: 'Video présentation',
  //     url: '/home'
  //   },
  //   {
  //     title: 'Contactez-nous',
  //     url: '/contact'
  //   }
  // ];

   playVideo() {
     VideoPlayer.player.toggleVideo();
   }

  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    private menu: MenuController,
    //private router: Router
  ) {
    this.initializeApp();
  }

  closeMenu(){
    this.menu.close()
  }

  initializeApp() {
    console.log('initializeApp');
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.statusBar.backgroundColorByHexString('#ffffff');
      //this.splashScreen.hide();
      SplashScreen.hide();
      StatusBar.setBackgroundColor({ color : '#ffffff'});
      registerWebPlugin(FileSharer);
      //this.router.navigateByUrl('home');
    });
  }
}
