import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, ExtraOptions } from '@angular/router';

const routerOptions: ExtraOptions = {
    // onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    urlUpdateStrategy: 'eager'
  };

const routes: Routes = [
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'contact', loadChildren: './contact/contact.module#ContactPageModule' },
  { path: 'colors', loadChildren: './colors/colors.module#ColorsPageModule' },
  { path: 'catalog', loadChildren: './catalog/catalog.module#CatalogPageModule' },
  { path: 'catalog-mk', loadChildren: './catalog-mk/catalog-mk.module#CatalogMkPageModule' },
  { path: 'color', loadChildren: './color/color.module#ColorPageModule' },
  { path: 'legals', loadChildren: './legals/legals.module#LegalsPageModule' },
  { path: '', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
