import { Component } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})

export class VideoPlayer {

	static player;

	private video;
	
	public display= false;
	public playing= true;

	constructor() {
		VideoPlayer.player= this;
	}

	ngAfterViewInit() {
		this.video= document.querySelector('#video-box > video');
	}

	toggleVideo() {

		if (this.display) {
		    this.video.pause();
		    this.video.currentTime= 0;
		    this.video.removeEventListener('ended', this._resetVideo, false);
		} else {
		    this.video.play();
		    this.video.addEventListener('ended', this._resetVideo, false);
		}
		this.display= !this.display;
	}

	togglePlay() {

		if ( this.video.paused ) {
		    this.video.play();
		    this.video.addEventListener('ended', this._resetVideo, false);
		} else {
		    this.video.pause();
		    this.video.removeEventListener('ended', this._resetVideo, false);
		}
		this.playing= !this.video.paused
	}

	private _resetVideo(e) {
		e.target.pause();
		e.target.currentTime= 0;
		VideoPlayer.player.display= false;
		VideoPlayer.player.playing= false;
	}
}
